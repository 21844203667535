/* html, body {
    width: 100%;
    height: 100%;
    padding: 0px;
    /* overflow: hidden; */

/* } */ 

.bg-royal {
    background-color: #0855a3;
}

.text-royal {
    color: #0855a3;
}

.btn-royal {
    background-color:#0855a3;
}

.text-white {
    color:#fff;
}

.text-black {
    color:#000;
}

.btn-bw-blue {
    background-color: #0855a3;
    color: white;
   }

.footer {
    background-color: #0855a3;
     border-top: 1px solid #E7E7E7;
    text-align: center;
     padding: 20px;
    position: fixed;
    left: 0;
     bottom: 0;
     height: 60px;
    width: 100%;
}
.profileImage{
    width: 250px;
    height: 250px;
    object-fit: cover;
}

.smallProfileImage {
    width: 120px;
    height: 120px;
    object-fit: cover;
}
.commentImage, .postImage {
    width: 120px;
    height: 120px;
    object-fit: cover;
 }
 .navProfileImage{
    width: 25px;
    height: 25px;
    object-fit: cover;
}

.lead-add-on {
    position: relative; 
    margin: auto;
    /* bottom:100px; */
}

.small-text {
    font-size: 12px;
}

.mr-ml-10{
    margin-right: 10px !important;
    margin-left: 10px !important;
}
.ml-10{
    margin-left: 10px !important;
}

.mt-8{
    margin-top: 8px !important;
}

.phantom {
    display: block;
    padding: 20px;
    height: 60px;
    width: 100%;
}

ul {
    list-style: none;
    margin-left: 0
}
.adminError {
    color: red;
    margin-left: 20px;
    font-size: 14px
}

li .profile {
    width: 250px;
    height: 40px;
    margin: auto;
    color: blue;
    padding-top: 6px;
    padding-bottom: 6px;
}

.zoom {
    transition: transform .2s; /* Animation */

  }
  
  .zoom:hover {
    transform: scale(1.02); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .deleteImage {
    position: relative;
    left: 250px;
    bottom: 125px;
  }
  .deletePinned {
    position:absolute; 
    right:10px;
  }
  .navbar-brand {
    text-align: center;
    width: 350px !important;
    height: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align:center;
    color: white;
    left: 100px;
   }

   .adminScrollBox {
        height:120px;
        width:90%;
        overflow:auto;
        border: 1px solid lightgrey;
        margin-top: 10px;
        margin-left: 10px;
        text-align: left !important;
   }

    .borderlist {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        padding-left: 10px !important;
       
    }

    .landing-logo {
       width: 600px;
       height: 300px;
       margin: auto;
   } 

   @media only screen and (max-width: 770px) {
    .landing-logo {
        max-width: 100%;
        height: auto !important;
    }
    .lead-add-on {
        position: relative; 
        margin: auto;
        bottom:70px;
    }
  }

   .navbar-dark .navbar-nav .nav-link {
       color: white !important;
   }

   .forgot-password {
    text-align: Center
   }

   .pad-10 {
    padding-bottom: 10px;
   }

   .pad-top-10 {
       padding-top: 10px !important;
   }

   .public-DraftStyleDefault-block {
       margin: 0px !important;
   }

   .textAreaSize {
    height: 300px !important;
   }

   .center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

  }

  .raise_add {
      padding-bottom: 5px;
  }

  .no-padding {
      padding-left: 0px !important;
      padding-right: 0px !important;
  }

  .custom-button-size {
    height: 30px;
    font-size : 12px;
  }

  .height-35 {
    height: 35px;;
  }

  .toMargin {
      margin-top: 16px;
  }

  .margin-10 {
    margin: 10px;
  }
  .landing-inner {
    padding-top: 50px !important;

 }

 .fullWidth {
     width: 100%;
 }
 
 .mt15 {
    margin-top: 15px;
 } 
 .mb15 {
    margin-bottom: 15px;
 }
 .mb5 {
    margin-bottom: 5px;
 }
 .mb0 {
    margin-bottom: 0px;
 }
 .test {
     margin-bottom: 15px !important;
 }

 .posRelative {
    position: relative;
 }

 .skills {
     padding-top: 5px;
     padding-bottom: 5px;
 }
 .contentInput {
     margin-left: 20px !important;
 }
  @media only screen and (max-height: 450px) {
     .landing-logo {
        position: relative; 
        margin: auto;
        bottom:150px;
    }
    .lead-add-on {
        position: relative; 
        margin: auto;
        bottom:150px;
    } 
  }

  @media only screen and (max-height: 375px) {
    .landing-logo {
       position: relative; 
       margin: auto;
       bottom:150px;
   }
   .lead-add-on {
       position: relative; 
       margin: auto;
       bottom:250px;
   } 
 }

 .alternativeButton{
     display: none;
 }

 @media only screen and (max-width: 9950px) {
    .commentImage {
        width: 90px;
        height: 90px;
        object-fit: cover;
     }
     .postImage {
        width: 90px;
        height: 90px;
        object-fit: cover;
     }
 }

 /* xs in bootstrap is 768 */
 @media only screen and (max-width: 768px) {
    .alternativeButton{
        display: block;
    }
    .imageResize{
        width: 40%;
        height: 40%;
        object-fit: cover;
    }
    .centerImage{
        text-align: center
    }

    .profilesImages {
        display: none;
    }
 }

  @media only screen and (max-width: 560px) {
    .profileImage{
        width: 200px;
        height: 200px;
        object-fit: cover;
    }
    .smallProfileImage {
        width: 110px;
        height: 110px;
        object-fit: cover;
    }
    .deleteImage {
        position: relative;
        left: 200px;
        bottom: 100px;
      }
 }
  /* xs in bootstrap is 768 */
  @media only screen and (max-width: 460px) {
    .smallProfileImage {
        width: 85px;
        height: 85px;
        object-fit: cover;
    }
 }

 .pinnedCard {
    /* width: 18 rem !important; */
    /* height: ; */
    margin-bottom: 10px;
 }


